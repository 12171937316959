import Vue from 'vue'
import VueRouter from 'vue-router'
// resetFont();
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/index'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import('../views/detail'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/mine',
		name: 'mine',
		component: () => import('../views/mine'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/myresponse',
		name: 'myresponse',
		component: () => import('../views/myresponse'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myreview',
		name: 'myreview',
		component: () => import('../views/myreview'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/mytoupiao',
		name: 'mytoupiao',
		component: () => import('../views/mytoupiao'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/mytian',
		name: 'mytian',
		component: () => import('../views/mytian'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/initiate',
		name: 'initiate',
		component: () => import('../views/initiate'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/initiatesuccess',
		name: 'initiatesuccess',
		component: () => import('../views/initiatesuccess'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/initiatedetail',
		name: 'initiatedetail',
		component: () => import('../views/initiatedetail'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/createtopic',
		name: 'createtopic',
		component: () => import('../views/createtopic'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/createmotion',
		name: 'createmotion',
		component: () => import('../views/createmotion'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/topicdetail',
		name: 'topicdetail',
		component: () => import('../views/topicdetail'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/motiondetail',
		name: 'motiondetail',
		component: () => import('../views/motiondetail'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/toupiaosuccess',
		name: 'toupiaosuccess',
		component: () => import('../views/toupiaosuccess'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/motionsuccess',
		name: 'motionsuccess',
		component: () => import('../views/motionsuccess'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/motionfail',
		name: 'motionfail',
		component: () => import('../views/motionfail'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('../views/agreement'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/editor',
		name: 'editor',
		component: () => import('../views/editor'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/test'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/mytopic',
		name: 'mytopic',
		component: () => import('../views/mine/mytopic'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/mymotion',
		name: 'mymotion',
		component: () => import('../views/mine/mymotion'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/myvote',
		name: 'myvote',
		component: () => import('../views/mine/myvote'),
		meta: {
			keepAlive: false
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
