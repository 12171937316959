import {
	Vue
} from "vue";
import config from './config.js';
import wx from 'weixin-js-sdk'
import axios from 'axios';
import getSign from './tools.js';
import imgUrl from '@/assets/share_img.jpg';
import mhImg from '@/assets/manghe.png'

function isWx() {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	var ua = window.navigator.userAgent.toLowerCase();
	// alert(JSON.stringify(ua))
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}
}

function wxConfig() {
	let a = 1;
	// if (isWx()) {
	// 	let params = new FormData()
	// 	axios.post(config.requestUrl + '/front/getAccessTokenAndJSTicket', params, {
	// 		headers: {
	// 			'Content-Type': 'multipart/form-data',
	// 		}
	// 	}).then(response => {
	// 		if (response.data.code == 0) {
	// 			let tokenArr = JSON.parse(response.data.obj);
	// 			let signArr = getSign(tokenArr['ticket']);
	// 			let link = config.host.substr(0, config.host.length - 1);
	// 			wx.config({
	// 				debug: false,
	// 				appId: signArr.appId,
	// 				timestamp: signArr.timestamp,
	// 				nonceStr: signArr.noncestr,
	// 				signature: signArr.signature,
	// 				jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData']
	// 			})
	// 			wx.ready(function() { //需在用户可能点击分享按钮前就先调用
	// 				wx.updateAppMessageShareData({
	// 					title: 'C2C电商平台', // 分享标题
	// 					desc: '', // 分享描述
	// 					link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	// 					imgUrl: link + imgUrl, // 分享图标
	// 					success: function() {
	// 						// 设置成功
	// 					}
	// 				})

	// 				wx.updateTimelineShareData({
	// 					title: 'C2C电商平台', // 分享标题
	// 					link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
	// 					imgUrl: link + imgUrl, // 分享图标
	// 					success: function() {
	// 						// 设置成功
	// 					}
	// 				})
	// 			});

	// 		} else {
	// 			console.log(222)
	// 		}
	// 	}, response => {
	// 		console.log(111)
	// 	})
	// }
}


function wxOrderConfig(id, name, img, isOpen, owner) {
	if (isWx()) {
		let params = new FormData()
		axios.post(config.requestUrl + '/front/getAccessTokenAndJSTicket', params, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then(response => {
			if (response.data.code == 0) {
				let tokenArr = JSON.parse(response.data.obj);
				let signArr = getSign(tokenArr['ticket']);
				let link = config.host + 'orderdetail?id=' + id + '&type=1';
				let imgUrl = '';
				if (isOpen == '是') {
					imgUrl = img;
				} else {
					imgUrl = config.host.substr(0, config.host.length - 1) + mhImg;
					name = '盲盒';
				}
				let desc = `藏品：${name}\r\n收藏者：${owner}`
				console.log(link);
				console.log(imgUrl)
				wx.config({
					debug: false,
					appId: signArr.appId,
					timestamp: signArr.timestamp,
					nonceStr: signArr.noncestr,
					signature: signArr.signature,
					jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData']
				})
				wx.ready(function() { //需在用户可能点击分享按钮前就先调用
					wx.updateAppMessageShareData({
						title: 'C2C电商平台', // 分享标题
						desc, // 分享描述
						link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl, // 分享图标
						success: function() {
							// 设置成功
						}
					})

					wx.updateTimelineShareData({
						title: 'C2C电商平台', // 分享标题
						link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl, // 分享图标
						success: function() {
							// 设置成功
						}
					})
				});

			} else {
				console.log(222)
			}
		}, response => {
			console.log(111)
		})
	}
}

function randomString() {
	let len = rand(3, 6);
	var $chars = 'ABCDEFGHIJKMNPOQRSTWXYZabcdefghijkmnoprstwxyz0123456789'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	var maxPos = $chars.length;
	var pwd = '';
	for (let i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));

	}
	return pwd;
}

function rand(min, max) {
	return parseInt(Math.random() * (max - min + 1) + min, 10);
}

function formatTime(val) {
	var date = new Date(val);
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return month + '月' + day + '日 ' + hours + ':' + minutes;
}

function formatDate(val) {
	var date = new Date(val);
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return `${year}.${month}.${day} ${hours}:${minutes}`;
}

function formatDate2(val) {
	var date = new Date(val);
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return `${year}.${month}.${day}`;
}

function getToday() {
	var date = new Date();
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	return `${year}/${month}/${day}`;
}

function compareTime(date) {
	let now = new Date();
	let cDate = new Date(date);
	if (cDate.getTime() > now.getTime()) {
		return 0;
	}
	return 1;
}

function isRealNum(val) {
	// isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，    
	if (val === "" || val == null) {
		return false;
	}
	if (!isNaN(val)) {
		//对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,   //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
		return true;
	} else {
		return false;
	}
}

/**
 * val 需要添加星号的值
 * front 前面保留几位
 * after 后面保留几位
 */
function addStar(val, front, after) {
	return val.substring(0, front) + '*'.repeat(val.length - (front + after)) + val.substring(val.length - after)
}
//乘
function accMul(arg1, arg2) {
	var m = 0,
		s1 = arg1.toString(),
		s2 = arg2.toString();
	try {
		m += s1.split(".")[1].length
	} catch (e) {

		// console.log(e)
	}
	try {
		m += s2.split(".")[1].length
	} catch (e) {
		// console.log(e)
	}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

//除法	 
function accDi(arg1, arg2) {
	var r1 = arg1.toString(),
		r2 = arg2.toString(),
		m, resultVal, d = arguments[2];
	m = (r2.split(".")[1] ? r2.split(".")[1].length : 0) - (r1.split(".")[1] ? r1.split(".")[1].length : 0);
	resultVal = Number(r1.replace(".", "")) / Number(r2.replace(".", "")) * Math.pow(10, m);
	return typeof d !== "number" ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
}

function floatSub(arg1, arg2) {
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split(".")[1].length
	} catch (e) {
		r2 = 0
	}
	m = Math.pow(10, Math.max(r1, r2));
	//动态控制精度长度    
	n = (r1 >= r2) ? r1 : r2;
	return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

function surplusDay(endTime) {
	let startTime = new Date();
	endTime = new Date(endTime);
	if (endTime < new Date()) return 0;
	return Math.ceil(Math.abs(endTime - startTime) / 86400000)
}

function formatCommentDate(date) {
	date = new Date(date);
	let nowDate = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let min = date.getMinutes();
	let sec = date.getSeconds();

	let year2 = nowDate.getFullYear();
	let month2 = nowDate.getMonth() + 1;
	let day2 = nowDate.getDate();
	let hour2 = nowDate.getHours();
	let min2 = nowDate.getMinutes();
	let sec2 = nowDate.getSeconds();
	let diffDay = Math.floor(Math.abs(nowDate - date) / 86400000)
	if (diffDay < 4) {
		if (diffDay >= 1) {
			return `${diffDay}天前`;
		}
		let diffHour = Math.floor(Math.abs(nowDate - date) / 3600000)
		if (diffHour >= 1) {
			return `${diffHour}小时前`;
		}
		let diffmin = Math.floor(Math.abs(nowDate - date) / 60000)
		if (diffmin >= 1) {
			return `${diffmin}分钟前`;
		}
		return '刚刚';
	}

	if (year != year2) {
		return `${year}-${month}-${day}`;
	}
	//3天前，小时前，分钟前， 刚刚
	if (month != month2 || day != day2) {
		return `${month}-${day}`;
	}

}

//转意符换成普通字符
function convertIdeogramToNormalCharacter(val) {
	const arrEntities = {
		'lt': '<',
		'gt': '>',
		'nbsp': ' ',
		'amp': '&',
		'quot': '"'
	};
	return val.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
		return arrEntities[t];
	});
}

// 获取富文本的纯文字内容
function getPlainText(richCont) {
	const str = richCont;
	let value = richCont;
	if (richCont) {
		// 方法一： 
		value = value.replace(/\s*/g, ""); //去掉空格
		value = value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
		value = value.replace(/↵/g, ""); //去掉所有的↵符号
		value = value.replace(/[\r\n]/g, "") //去掉回车换行
		value = value.replace(/&nbsp;/g, "") //去掉空格
		value = convertIdeogramToNormalCharacter(value);
		return value;

		// 方法二： 
		// value = value.replace(/(\n)/g, "");
		// value = value.replace(/(\t)/g, "");
		// value = value.replace(/(\r)/g, "");
		// value = value.replace(/<\/?[^>]*>/g, "");
		// value = value.replace(/\s*/g, "");
		// value = convertIdeogramToNormalCharacter(value);
		// return value;
	} else {
		return null;
	}
}

function formatLikeTotal(num) {
	if (num >= 10000) {
		num = (Math.floor(num / 1000) / 10).toFixed(1) + 'w'
	}
	return num;
}

function formatAddress(address) {
	return address.substr(0, 6) + '...' + address.substr(-4, 4)

}
export default {
	isWx,
	wxConfig,
	randomString,
	formatTime,
	compareTime,
	formatDate,
	wxOrderConfig,
	isRealNum,
	addStar,
	accMul,
	accDi,
	floatSub,
	formatDate2,
	getToday,
	surplusDay,
	getPlainText,
	formatCommentDate,
	formatLikeTotal,
	formatAddress
}